import type { CourseDocument } from '@goschool/model'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useFirebaseApp } from '@progos/firebase-chat'
import { getDownloadURL, getStorage, ref } from 'firebase/storage'
import { Document, Page, pdfjs, Thumbnail } from 'react-pdf'
import { Stack, styled, alpha } from '@mui/material'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'
import type { PDFDocumentProxy } from 'pdfjs-dist'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString()

export function PdfViewer({ document }: { document: CourseDocument | undefined }) {
  const app = useFirebaseApp()
  const [pdfUrl, setPdfUrl] = useState<string>()
  useEffect(
    () => {
      const path = document?.storage_ref
      const storage = getStorage(app)
      if (path!=null) {
        const pdfRef = ref(storage, path)
        getDownloadURL(pdfRef).then(url => {
          setPdfUrl(url)
        })
      }
    },
    [document, app]
  )

  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pdf, setPdf] = useState<PDFDocumentProxy>()
  const numPages = useMemo(() => pdf?.numPages, [pdf])

  const onDocumentLoadSuccess = useCallback(
    (pdf: PDFDocumentProxy) => {
      setPdf(pdf)
    }, []
  )

  const onItemClick = useCallback(
    ({ pageNumber }: { pageNumber: number }) => {
      setPageNumber(pageNumber)
    }, []
  )


  return <Stack direction="column" overflow="hidden" alignItems="center" justifyContent="flex-start">
    {/*<Pagination disabled={numPages==null} count={numPages} page={pageNumber}*/}
    {/*            siblingCount={2} boundaryCount={2}*/}
    {/*            onChange={(event, value) => setPageNumber(value)} />*/}

    <Document file={pdfUrl} onItemClick={onItemClick} onLoadSuccess={onDocumentLoadSuccess}>
      <Stack sx={{ height: '800px' }} direction="row" alignItems="stretch" justifyContent="stretch">
        <Toc>
          {Array.from(new Array(numPages), (_el, index) => (
            <Thumbnail
              key={`thumbnail_${index + 1}`}
              pageNumber={index + 1}
              width={100}
            ><PageNumber><span>{index+1}</span></PageNumber></Thumbnail>
          ))}
        </Toc>
        <PageView>
          <Page pageNumber={pageNumber ?? 1} scale={1} height={800} />
        </PageView>
      </Stack>
    </Document>

    {/*<Pagination disabled={numPages==null} count={numPages} page={pageNumber}*/}
    {/*            siblingCount={1} boundaryCount={2}*/}
    {/*            onChange={(event, value) => setPageNumber(value)} />*/}
  </Stack>
}

const Toc = styled('div', { name: 'Toc', slot: 'Root' })(({ theme }) => ({
  flex: '0 0 120px',
  overflowY: 'scroll',
  borderRight: `1px solid ${theme.palette.divider}`,
  backgroundColor: theme.palette.background.paper,
  '& .react-pdf__Thumbnail__page': {
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
  }
}))

const PageNumber = styled('div', {name: 'PageNumber', slot: 'Root'})(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.text.primary,
  '& span': {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    backgroundColor: theme.palette.background.paper,
    boxShadow: `0 0 10px ${alpha(theme.palette.divider, 0.5)}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: theme.typography.caption.fontSize,
    fontWeight: 'bold',
  }
}))

const PageView = styled('div', { name: 'PageView', slot: 'Root' })(({theme}) => ({
  flex: '0 0 auto',
  '& .react-pdf__Page': {
    margin: theme.spacing(1),
    boxShadow: theme.shadows[3],
  }
}))
